 <template>
  <!-- the desktop navbar has 3 sections: topbar/promo, main nav, bottom bar/risk free to you -->
  <nav class="hidden lg:block">
    <!-- DESKTOP -->
    <div>
      <!-- top bar/promo -->
      <PromoBar />
      <!-- main nav -->
      <div class="bg-blue-600 text-blue-50 py-2 px-4 space-y-3">
        <!-- row 1/menus -->
        <div class="flex items-center justify-between">
          <div class="text-2xl font-semibold tracking-tight">
            <router-link :to="'/' + selectedPartner.id + '/'">
              {{ selectedPartner.name }}
            </router-link>
          </div>
          <div class="flex items-center justify-between text-sm space-x-4">
            <!-- sort by cost to org -->
            <div
              class="
                hidden
                rounded-full
                py-2
                px-4
                text-blue-50
                bg-blue-50 bg-opacity-25
                hover:bg-blue-700
                transition
                cursor-pointer
                flex
                items-center
                relative
              "
            >
              <div class="mr-5">Sort by Cost to Organization</div>
              <div class="absolute top-2 right-3">
                <i class="mdi mdi-chevron-down"></i>
              </div>
            </div>
            <!-- browse by destination -->
            <BrowseByDestination />
          </div>
          <div class="flex items-center justify-between text-sm space-x-4">
            <!-- menu -->
            <Menu />
            <div class="flex items-center justify-between space-x-2">
              <!-- wishlist -->
              <router-link
                :to="'/' + $store.state.selectedPartner.id + '/wishlist'"
                v-if="$store.state.user"
                class="
                  text-xl
                  cursor-pointer
                  transition
                  rounded-full
                  h-9
                  w-9
                  flex
                  items-center
                  justify-center
                "
                :class="
                  $store.state.userWishList.length
                    ? 'bg-pink-600 hover:bg-pink-700'
                    : 'hover:bg-blue-50 hover:bg-opacity-25'
                "
              >
                <i class="mdi mdi-heart"></i>
              </router-link>
              <div
                @click="
                  $store.state.authModal.display = true;
                  $store.state.authModal.action = 'signup';
                "
                v-else
                class="
                  text-xl
                  cursor-pointer
                  hover:bg-blue-50 hover:bg-opacity-25
                  transition
                  rounded-full
                  h-9
                  w-9
                  flex
                  items-center
                  justify-center
                "
              >
                <i class="mdi mdi-heart"></i>
              </div>
              <!-- account -->
              <AccountMenu />
            </div>
          </div>
        </div>
        <!-- row 2/links/routes -->
        <div
          class="flex items-center justify-center space-x-4 font-medium py-1"
        >
          <div v-for="(item, index) in routes" :key="index">
            <router-link
              :to="item.path"
              class="
                text-blue-50
                rounded-2xl
                px-4
                py-2
                hover:bg-blue-700
                transition
              "
              exact-active-class="bg-white bg-opacity-30 hover:pointer-events-none"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
      </div>
      <!-- bottom bar/risk free to you -->
      <div
        class="
          bg-gray-200
          text-gray-800
          p-2
          text-sm text-center
          flex
          items-center
          justify-center
          space-x-4
        "
      >
        <div>High Quality Vacation Accommodation Packages.</div>
        <div class="font-semibold">RISK FREE TO YOU.</div>
        <div class="italic">Packages Don't Sell. You Don't Pay!</div>
      </div>
    </div>
  </nav>
</template>

<script>
import BrowseByDestination from "@/components/Navbar/Desktop/BrowseByDestination";
import AccountMenu from "@/components/Navbar/Desktop/AccountMenu";
import Menu from "@/components/Navbar/Desktop/Menu";
import PromoBar from "@/components/Navbar/PromoBar";
export default {
  components: {
    BrowseByDestination,
    Menu,
    AccountMenu,
    PromoBar,
  },
  data: () => ({
    routes: [],
  }),
  computed: {
    selectedPartner() {
      return this.$store.state.selectedPartner;
    },
  },
  methods: {
    buildRoutes() {
      this.routes = [];
      let routes = this.$router.options.routes.filter(
        (i) => i.meta.mainNavLink
      );
      routes.forEach((i) => {
        let item = {
          path:
            "/" +
            this.$store.state.selectedPartner.id +
            i.path.replace("/:partner", ""),
          name: i.name,
        };
        this.routes.push(item);
      });
    },
  },
  watch: {
    "$store.state.selectedPartner": {
      immediate: true,
      deep: true,
      handler(selectedPartner) {
        if (!selectedPartner) return;
        this.selectedPartnerID = selectedPartner.id;
        this.buildRoutes();
      },
    },
  },
};
</script>

  
