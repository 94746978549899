<template>
  <footer class="space-y-8 text-gray-800 bg-gray-200 p-7">
    <!-- subscribe -->
    <div
      class="
        flex flex-col
        md:flex-row
        items-center
        justify-center
        gap-6
        md:gap-12
      "
    >
      <div>
        <h4 class="text-2xl font-extrabold text-center sm:text-3xl">
          Need free tips and promos?
        </h4>
      </div>
      <div>
        <button
          @click="$store.state.subscribeModal = true"
          class="
            inline-flex
            items-center
            justify-between
            px-4
            py-2
            space-x-2
            text-sm
            font-semibold
            tracking-wide
            uppercase
            transition
            bg-blue-600
            rounded-full
            cursor-pointer
            text-blue-50
            hover:bg-blue-700
          "
        >
          <span>Subscribe</span>
          <i class="text-xl mdi mdi-bell"></i>
        </button>
      </div>
    </div>
    <!-- copyright -->
    <div class="text-sm text-center">
      <i class="mdi mdi-copyright"></i> {{ new Date().getFullYear() }} | Auction
      Vacations
    </div>
  </footer>
</template>
 