<template>
  <transition name="fade">
    <!-- wrapper/overlay -->
    <div
      @click="close"
      v-if="$store.state.authModal.display"
      class="
        fixed
        inset-0
        bg-gray-900 bg-opacity-80
        close
        flex
        items-start
        justify-center
      "
    >
      <!-- dialog card -->
      <div
        class="bg-gray-50 rounded shadow p-4 w-full relative mt-12 mx-6"
        :class="
          $store.state.authModal.action == 'signup' ? 'max-w-5xl' : 'max-w-xl'
        "
      >
        <!-- close btn -->
        <button
          @click="$store.state.authModal.display = false"
          class="
            cursor-pointer
            hover:bg-gray-600 hover:bg-opacity-25
            transition
            rounded-full
            h-10
            w-10
            flex
            items-center
            justify-center
            text-center
            close
            absolute
            top-2
            right-2
          "
        >
          <i
            class="mdi mdi-close-circle-outline text-2xl text-gray-600"
            style="margin-left: 1px; margin-top: 1px"
          ></i>
        </button>
        <SignUp />
        <SignIn />
      </div>
    </div>
  </transition>
</template>

<script>
import SignUp from "@/components/AuthModal/SignUp";
import SignIn from "@/components/AuthModal/SignIn";
export default {
  components: { SignUp, SignIn },
  methods: {
    close(e) {
      if (e.target.classList.contains("close"))
        this.$store.state.authModal.display = false;
    },
  },
};
</script>
 