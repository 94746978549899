<template>
  <transition name="fade">
    <div class="relative">
      <!-- overlay to close on click outside-->
      <div
        class="fixed inset-0 z-10 bg-gray-900 bg-opacity-30"
        v-if="showMenu"
        @click="showMenu = false"
      ></div>

      <!-- activator -->
      <div
        class="
          rounded-full
          py-2
          px-4
          text-blue-50
          bg-blue-50 bg-opacity-25
          cursor-pointer
          flex
          items-center
          hover:bg-blue-700
          transition
          relative
        "
        @click="showMenu = !showMenu"
      >
        <div class="mr-5">Browse by Destination</div>
        <div class="absolute top-2 right-3">
          <i class="mdi mdi-chevron-down"></i>
        </div>
      </div>

      <!-- dropdown menu -->
      <transition name="fade">
        <div
          class="
            absolute
            right-2
            top-12
            bg-white
            dark:bg-gray-600
            text-gray-700
            dark:text-gray-300
            z-10
            shadow
            rounded
            w-44
            text-sm
            overflow-hidden
            py-1
          "
          v-if="showMenu"
        >
          <!-- items -->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="relative"
            @click="item.showChildren = !item.showChildren"
          >
            <div
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
              "
            >
              {{ item.headerText }}
            </div>
            <div class="absolute top-2 right-3">
              <i class="mdi mdi-chevron-down"></i>
            </div>
            <transition name="fade">
              <div v-if="item.showChildren">
                <div
                  v-for="(item, index) in item.children"
                  :key="index"
                  @click="onClick($event)"
                  class="
                    block
                    p-1
                    pl-6
                    text-xs
                    font-medium
                    text-gray-600
                    transition
                    cursor-pointer
                    hover:bg-gray-200 hover:text-gray-900
                  "
                >
                  {{ item }}
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    onClick(e) {
      let textContent = e.target.textContent.trim().toLowerCase();
      if (!this.$route.fullPath.includes(textContent)) {
        this.$router.push(
          "/" +
            this.$store.state.selectedPartner.id +
            "/destinations/" +
            textContent
        );
      }
      this.showMenu = false;
      setTimeout(() => {
        this.items.forEach((i) => {
          i.showChildren = false;
        });
      }, 100);
    },
  },
  watch: {
    showMenu(menu) {
      // closes all menu children when menu is closed or overlay is clicked to close menu
      if (!menu) {
        setTimeout(() => {
          this.items.forEach((i) => {
            i.showChildren = false;
          });
        }, 100);
      }
    },
  },
  data: () => ({
    showMenu: false,
    items: [
      {
        headerText: "United States",
        showChildren: false,
        children: [
          "Alaska",
          "Arizona",
          "California",
          "Colorado",
          "Hawaii",
          "Illinois",
          "Kansas",
          "Louisiana",
          "Massachusetts",
          "Missouri",

          "Nevada",
          "New York",
          "North Carolina",
          "Pennsylvania",
          "Tennessee",
          "Texas",
          "Virginia",
          "Washington D.C.",
        ],
      },
      {
        headerText: "Canada",
        showChildren: false,
        children: ["Alberta", "British Columbia"],
      },
      {
        headerText: "Caribbean",
        showChildren: false,
        children: ["Bahamas", "Jamaica", "Dominican Republic", "Bermuda"],
      },
      {
        headerText: "Europe",
        showChildren: false,
        children: [
          "Austria",
          "Czech Republic",
          "England",
          "France",
          "Greece",
          "Ireland",
          "Italy",
          "Spain",
        ],
      },
      {
        headerText: "Mexico",
        showChildren: false,
        children: [
          "Cancun",
          "Cozumel",
          "Los Cabos",
          "Puerto Vallarta",
          "Riviera Maya",
        ],
      },
      {
        headerText: "Asia",
        showChildren: false,
        children: ["Indonesia", "India"],
      },
      {
        headerText: "South America",
        showChildren: false,
        children: ["Argentina"],
      },
      {
        headerText: "Australia",
        showChildren: false,
        children: ["Gold Coast"],
      },
    ],
  }),
};
</script>
