import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

// plugins
import "@/firebase";

// vue3-date-time-picker
// @/components/authModal
// https://vue3datepicker.com/api/props/#menuclassname
// https://github.com/Vuepic/vue3-date-time-picker

// NProgress
// https://www.npmjs.com/package/nprogress
// https://github.com/rstacruz/nprogress
// https://github.com/rstacruz/nprogress/issues/187
import "nprogress/nprogress.css";

// Vue3 Smooth Scroll
// https://www.npmjs.com/package/vue3-smooth-scroll
// https://github.com/laineus/vue3-smooth-scroll
import VueSmoothScroll from "vue3-smooth-scroll";

// vueToastification2
// https://github.com/Maronato/vue-toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

// https://www.npmjs.com/package/vue-pdf-embed
// https://github.com/hrynko/vue-pdf-embed

// GOOGLE ANALYTICS
// https://matteo-gabriele.gitbook.io/vue-gtag/v/v2.0.0/auto-tracking
// https://github.com/MatteoGabriele/vue-gtag
// v4 connected 3/10/2023
import VueGtag from "vue-gtag";

createApp(App)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .use(Toast, toastOptions)
  .use(
    VueGtag,
    {
      config: { id: "UA-119395390-1" },
    },
    router
  )
  .mount("#app");
