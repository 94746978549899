<template>
  <div class="min-h-screen antialiased">
    <NavbarDesktop />
    <NavbarMobile />
    <div class="mx-auto max-w-7xl text-gray-700 p-6">
      <router-view />
      <BackToTopBtn />
    </div>
    <YouTubeModal />
    <SubscribeModal />
    <AuthModal />
  </div>
  <AppFooter />
</template>

<script>
import { db, firebase } from "@/firebase";
import NavbarDesktop from "@/components/Navbar/Desktop/Navbar";
import NavbarMobile from "@/components/Navbar/Mobile/Navbar";
import BackToTopBtn from "@/components/BackToTopBtn";
import YouTubeModal from "@/components/YouTubeModal";
import SubscribeModal from "@/components/SubscribeModal";
import AuthModal from "@/components/AuthModal/Index";
import AppFooter from "@/components/AppFooter";
import NProgress from "nprogress";
export default {
  components: {
    NavbarDesktop,
    NavbarMobile,
    BackToTopBtn,
    YouTubeModal,
    SubscribeModal,
    AuthModal,
    AppFooter,
  },
  mounted() {
    // get packages
    NProgress.start();
    db.collection("packages")
      .where("available", "==", true)
      .orderBy("homePageSortingPriority")
      .get()
      .then((snap) => {
        if (this.$store.state.retailPackages.length) return;
        snap.forEach((doc) =>
          this.$store.state.retailPackages.push(doc.data())
        );
        NProgress.done();
      });
    // check for firebase user
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.state.user = user;
        db.collection("users")
          .doc(user.uid)
          .onSnapshot(
            (doc) => {
              let data = doc.data();
              this.$store.state.userAccountData = data;
              this.$store.state.userWishList = data.wishList;
            },
            (error) => {
              console.log(error);
            }
          );
        return;
      } else {
        this.$store.state.user = "";
        this.$store.state.userAccountData = "";
        this.$store.state.userWishList = "";
        return;
      }
    });
  },
};
</script>
