<template>
  <!-- to keep seperate animations, component contains both an overlay and drawer -->
  <div class="lg:hidden">
    <!-- overlay -->
    <transition name="fade">
      <div
        class="fixed inset-0 bg-black bg-opacity-25 z-0"
        v-if="$store.state.mobileNavDrawer"
        @click="$store.state.mobileNavDrawer = false"
      ></div>
    </transition>
    <!-- drawer -->
    <transition name="fade">
      <div
        class="
          bg-gray-100
          text-gray-700
          dark:bg-gray-700 dark:text-gray-300
          w-72
          sm:w-80
          fixed
          overflow-y-scroll
          h-full
          right-0
          top-0
          z-10
          space-y-4
        "
        v-if="$store.state.mobileNavDrawer"
      >
        <div class="space-y-2 py-4 border-b border-gray-300">
          <h2 class="flex items-center justify-between">
            <span class="text-xl font-bold tracking-tight block ml-4">
              {{ $store.state.selectedPartner.name }}
            </span>
            <!-- close btn -->
            <button
              class="
                p-2
                rounded-full
                text-gray-600
                dark:text-gray-400
                transition
                hover:bg-gray-300
                h-8
                w-8
                grid
                place-content-center
              "
              @click="$store.state.mobileNavDrawer = false"
            >
              <i class="mdi mdi-close-circle-outline text-2xl"></i>
            </button>
          </h2>
          <!-- content -->
          <!-- <h3 class="text-lg font-medium dark:text-gray-400">Modal Subtitle</h3> -->
        </div>
        <div class="flex flex-col gap-3 px-3">
          <router-link
            v-for="(item, index) in routes"
            :key="index"
            :to="item.path"
            class="
              px-4
              py-3
              font-medium
              transition
              hover:bg-gray-300
              cursor-pointer
              rounded
            "
          >
            {{ item.name }}
          </router-link>
        </div>
        <!-- sign in -->
        <div class="flex flex-col gap-3 px-3">
          <a
            @click="
              $store.state.mobileNavDrawer = false;
              $store.state.authModal.display = true;
              $store.state.authModal.action = 'signin';
            "
            class="
              rounded
              px-4
              py-3
              font-medium
              transition
              hover:bg-gray-300
              cursor-pointer
            "
            >Sign In</a
          >
        </div>
        <!-- sign up -->
        <div class="flex flex-col gap-3 px-3">
          <a
            @click="
              $store.state.mobileNavDrawer = false;
              $store.state.authModal.display = true;
              $store.state.authModal.action = 'signup';
            "
            class="
              rounded
              px-4
              py-3
              font-medium
              transition
              hover:bg-gray-300
              cursor-pointer
            "
            >Sign Up</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

 <script>
export default {
  data: () => ({
    routes: [],
  }),
  methods: {
    buildRoutes() {
      this.routes = [];
      let routes = this.$router.options.routes.filter(
        (i) => i.meta.mainNavLink
      );
      routes.forEach((i) => {
        let item = {
          path:
            "/" +
            this.$store.state.selectedPartner.id +
            i.path.replace("/:partner", ""),
          name: i.name,
        };
        this.routes.push(item);
      });
    },
  },
  watch: {
    "$store.state.selectedPartner": {
      immediate: true,
      deep: true,
      handler(selectedPartner) {
        if (!selectedPartner) return;
        this.selectedPartnerID = selectedPartner.id;
        this.buildRoutes();
      },
    },
  },
};
</script>