import { createStore } from 'vuex'

export default createStore({

  state: {
    user: "",
    userAccountData: "",
    userWishList: [],
    retailPackages: [],
    selectedPartner: "",
    subscribeModal: false,
    youTubeModal: false,
    youTubeItem: "",
    mobileNavDrawer: false,
    authModal: {
      display: false,
      action: "signup"
    },
    partners: [
      {
        id: "av",
        name: "Auction Vacations",
        email: ["booking@silentauctionvacations.com"],
        phone: "(407) 745-1175",
        displayCostToOrg: false
      },
      {
        id: "alpert",
        name: "Alpert Enterprises",
        email: ["info@alpertenterprises.com"],
        phone: "(954) 658-9465",
        displayCostToOrg: true
      },
      {
        id: "ktba",
        name: "Kip Toner Benefit Auctions",
        email: ["kip.gm@ecjam.com"],
        phone: "(206) 842-7412",
        displayCostToOrg: false
        // kip.ceo@ktba.net
      },
      {
        id: "tt",
        name: "A Higher Calling Benefit Auctions",
        email: ["toney@highercallingba.com"],
        phone: "(636) 295-1370",
        displayCostToOrg: false
      },
      {
        id: "ba",
        name: "Wisely Benefit Auctioneer Service",
        email: ["tony@benefitauctioneer.us"],
        phone: "(407) 745-1175",
        displayCostToOrg: true
      },
      {
        id: "baf",
        name: "Benefit Auction Fundraising",
        email: ["info@benefitauctionfundraising.com"],
        phone: "(503) 970-0999",
        displayCostToOrg: true
      },
      {
        id: "cbu",
        name: "Charity Benefits Unlimited",
        email: ["info@cbuauctions.com"],
        phone: "(480) 483-0525",
        displayCostToOrg: true
        // noelle@cbuauctions.com
      },
      {
        id: "gb",
        name: "GalaBid",
        email: ["kelly@crowdcomms.com"],
        phone: "(206) 972-2100",
        displayCostToOrg: true
      },
      {
        id: "gas",
        name: "Greer Auction Services",
        email: ["mike@greerauctions.com"],
        phone: "(713) 825-2300",
        displayCostToOrg: false
      },
      {
        id: "sk",
        name: "Stephen Kilbreath",
        email: ["stephen@kilbreathauctions.com"],
        phone: "(206) 963-7148",
        displayCostToOrg: true
      },
      {
        id: "cw",
        name: "CharityWorks",
        email: ["ira@charityworks.net"],
        phone: "(925) 250-6968",
        displayCostToOrg: false
      },
      // {
      //   id: "kr",
      //   name: "Kevin Rutter's Hot Auctioneering",
      //   email: ["charityauctioneering@gmail.com"],
      //   phone: "(720) 329-8626",
      //   displayCostToOrg: true
      // },
      // changed to SG per KR gmail request on 1/11/2022
      {
        id: "sg",
        name: "Smart Gala",
        email: ["kevin@thesmartgala.com"],
        phone: "(720) 329-8626",
        displayCostToOrg: true
      },
    ]
  },

  mutations: {
  },

  actions: {
  },

})
