<template>
  <transition name="fade">
    <div class="relative">
      <!-- overlay to close on click outside-->
      <div
        class="fixed inset-0 z-10 bg-gray-900 bg-opacity-30"
        v-if="showMenu"
        @click="showMenu = false"
      ></div>

      <!-- activator -->
      <div
        @click="showMenu = true"
        class="
          text-xl
          cursor-pointer
          transition
          rounded-full
          h-9
          w-9
          grid
          place-content-center
        "
        :class="
          $store.state.user
            ? 'bg-pink-600 hover:bg-pink-700'
            : 'hover:bg-blue-50 hover:bg-opacity-25'
        "
      >
        <i class="mdi mdi-account"></i>
      </div>

      <!-- dropdown menu -->
      <transition name="fade">
        <div
          class="
            absolute
            right-2
            top-12
            bg-white
            dark:bg-gray-600
            text-gray-700
            dark:text-gray-300
            z-10
            shadow
            rounded
            w-32
            text-sm
            overflow-hidden
            py-1
          "
          v-if="showMenu"
        >
          <!-- sign in -->
          <div v-if="!$store.state.user">
            <a
              @click="
                showMenu = false;
                $store.state.authModal.display = true;
                $store.state.authModal.action = 'signin';
              "
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
              "
            >
              <div>
                <i
                  class="mdi mdi-account-arrow-right text-xl text-blue-600"
                ></i>
              </div>
              <div>Sign In</div></a
            >
          </div>
          <!-- sign up -->
          <div v-if="!$store.state.user">
            <a
              @click="
                showMenu = false;
                $store.state.authModal.display = true;
                $store.state.authModal.action = 'signup';
              "
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
              "
            >
              <div>
                <i class="mdi mdi-account-plus text-xl text-blue-600"></i>
              </div>
              <div>Sign Up</div></a
            >
          </div>
          <!-- wishlist -->
          <div v-if="$store.state.user" @click="showMenu = false">
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/wishlist'"
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
              "
            >
              <div>
                <i class="mdi mdi-heart-outline text-xl text-blue-600"></i>
              </div>
              <div>Wish List</div>
            </router-link>
          </div>
          <!-- sign out -->
          <div v-if="$store.state.user">
            <a
              @click="signOut"
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
              "
            >
              <div>
                <i class="mdi mdi-logout text-xl text-blue-600"></i>
              </div>
              <div>Sign Out</div></a
            >
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { firebase } from "@/firebase";
import { useToast } from "vue-toastification";
export default {
  data: () => ({
    toast: useToast(),
    showMenu: false,
  }),
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.showMenu = false;
          this.$store.state.authModal.action = "signup";
          this.$store.state.user = "";
          this.$router.push("/");
          this.toast.success("Sign Out Successful");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClick() {
      if (this.$store.state.user)
        return this.$router.push(
          "/" + this.$store.state.selectedPartner.id + "/account/"
        );
      return (this.showMenu = true);
    },
  },
};
</script>