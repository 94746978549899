<template>
  <transition name="fade">
    <!-- wrapper -->
    <div
      @click="close"
      v-if="$store.state.youTubeModal"
      class="
        fixed
        inset-0
        bg-gray-900 bg-opacity-80
        close
        flex
        items-center
        justify-center
      "
    >
      <!-- card -->
      <div class="bg-gray-50 rounded shadow p-4 max-w-2xl w-full relative mx-6">
        <!-- close btn -->
        <button
          @click="$store.state.youTubeModal = false"
          class="
            cursor-pointer
            hover:bg-gray-600 hover:bg-opacity-25
            transition
            rounded-full
            h-10
            w-10
            flex
            items-center
            justify-center
            text-center
            close
            absolute
            top-2
            right-2
          "
        >
          <i
            class="mdi mdi-close-circle-outline text-2xl text-gray-600"
            style="margin-left: 1px; margin-top: 1px"
          ></i>
        </button>
        <!-- title -->
        <h3 class="text-2xl font-semibold uppercase mr-10">
          {{ $store.state.youTubeItem.packageCode }} -
          {{ $store.state.youTubeItem.destination }}
        </h3>
        <!-- subtitle -->
        <div
          v-html="$store.state.youTubeItem.title"
          class="font-medium my-6 text-lg"
        ></div>
        <!-- video -->
        <iframe
          :src="$store.state.youTubeItem.youtubeVideoUrl"
          height="400"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-full rounded shadow"
        ></iframe>

        <!-- footer -->
        <div
          class="
            mt-8
            mb-5
            flex flex-col
            gap-6
            items-center
            sm:flex-row
            justify-center
          "
        >
          <!-- print PDF -->
          <button
            @click="onPdf"
            class="
              uppercase
              bg-blue-600
              text-blue-50
              hover:bg-blue-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
              w-full
              sm:w-auto
            "
          >
            <span>Print Package PDF</span>
            <i class="mdi mdi-file-pdf-box text-xl"></i>
          </button>
          <!-- add to wishList -->
          <!-- user -->
          <button
            @click="onWishList($store.state.youTubeItem)"
            v-if="$store.state.user"
            class="
              uppercase
              bg-pink-600
              text-pink-50
              hover:bg-pink-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
              w-full
              sm:w-auto
            "
          >
            <span>{{
              $store.state.userWishList.length &&
              $store.state.userWishList.some(
                (i) => i == $store.state.youTubeItem.packageCode
              )
                ? "Remove from Wish List"
                : "Add to Wish List"
            }}</span>
            <i
              class="mdi text-xl"
              :class="
                $store.state.userWishList.length &&
                $store.state.userWishList.some(
                  (i) => i == $store.state.youTubeItem.packageCode
                )
                  ? 'mdi-heart'
                  : 'mdi-heart-outline'
              "
            ></i>
          </button>
          <!-- !user -->
          <button
            @click="
              $store.state.authModal.display = true;
              $store.state.authModal.action = 'signup';
            "
            v-else
            class="
              uppercase
              bg-pink-600
              text-pink-50
              hover:bg-pink-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-2
              inline-flex
              items-center
              justify-between
              w-full
              sm:w-auto
            "
          >
            <span>Add to Wish List</span>
            <i class="mdi mdi-heart-outline text-xl"></i>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { firebase, db } from "@/firebase";
import { useToast } from "vue-toastification";
import NProgress from "nprogress";
export default {
  data: () => ({
    toast: useToast(),
  }),
  methods: {
    close(e) {
      if (e.target.classList.contains("close"))
        this.$store.state.youTubeModal = false;
    },
    onPdf() {
      if (this.$store.state.user) {
        window
          .open(this.$store.state.youTubeItem.pdfDownloadUrl, "_blank")
          .focus();
      } else {
        this.$store.state.authModal.display = true;
        this.$store.state.authModal.action = "signup";
      }
    },
    onWishList(item) {
      if (!item) return;
      // check if item is already in wishList
      let isInWishList = this.$store.state.userWishList.some(
        (i) => i == item.packageCode
      );
      if (isInWishList) {
        // if in wishList => remove
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayRemove(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Removed from Wish List");
          });
      } else {
        // not in wishList => add to wishList
        NProgress.start();
        db.collection("users")
          .doc(this.$store.state.userAccountData.id)
          .update({
            wishList: firebase.firestore.FieldValue.arrayUnion(
              item.packageCode
            ),
          })
          .then(() => {
            NProgress.done();
            this.toast.success("Added to Wish List");
          });
      }
    },
  },
};
</script>