<template>
  <div
    class="
      bg-pink-600
      text-pink-50 text-sm
      transition
      hover:bg-pink-700
      cursor-pointer
      text-center
      p-1
      font-medium
      hidden
    "
  >
    Click here to sign up today and earn $50 towards your next event!
    <span class="italic">(It's free)</span>
  </div>
</template>

<script>
export default {};
</script>

 