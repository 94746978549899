<template>
  <nav class="block lg:hidden">
    <div>
      <!-- top bar/promo -->
      <div>
        <PromoBar />
      </div>
      <div class="bg-blue-600 text-blue-50 py-2 px-4 space-y-3">
        <!-- main row -->
        <div class="flex items-center justify-between">
          <!-- brand -->
          <div class="text-2xl font-semibold tracking-tight">
            <router-link :to="'/' + $store.state.selectedPartner.id + '/'">
              {{ $store.state.selectedPartner.name }}
            </router-link>
          </div>
          <div>
            <button
              @click="$store.state.mobileNavDrawer = true"
              class="
                h-10
                w-10
                transition
                hover:bg-blue-700
                rounded-full
                grid
                place-content-center
              "
            >
              <i class="mdi mdi-menu text-2xl text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- drawer -->
  <Drawer />
</template>
 
 <script>
import PromoBar from "@/components/Navbar/PromoBar";
import Drawer from "@/components/Navbar/Mobile/Drawer";
export default {
  components: { PromoBar, Drawer },
};
</script>