<template>
  <transition name="fade">
    <div
      v-if="$store.state.authModal.action == 'signup'"
      class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 lg:gap-16"
    >
      <!-- col 1 / form -->
      <div>
        <!-- title -->
        <h3 class="text-xl font-semibold uppercase mr-6">
          {{ title }}
        </h3>
        <!-- subtitle -->
        <h4 class="mb-6 mt-2">{{ subtitle }}</h4>

        <!-- form -->
        <div class="flex flex-col gap-5">
          <!-- email -->
          <div>
            <input
              @blur="v$.email.$touch()"
              v-model.trim="email"
              type="email"
              name="email"
              id="email"
              autocomplete="email"
              :disabled="loading"
              class="
                block
                w-full
                shadow-sm
                py-3
                px-4
                placeholder-gray-500
                rounded-md
                bg-gray-100
              "
              :class="
                v$.email.$error
                  ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
              "
              placeholder="Email *"
            />
            <p class="text-red-500 font-semibold mt-2" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}
            </p>
          </div>
          <!-- eventName -->
          <div>
            <input
              @blur="v$.eventName.$touch()"
              v-model.trim="eventName"
              type="text"
              name="eventName"
              id="eventName"
              :disabled="loading"
              class="
                block
                w-full
                shadow-sm
                py-3
                px-4
                placeholder-gray-500
                rounded-md
                bg-gray-100
              "
              :class="
                v$.eventName.$error
                  ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
              "
              placeholder="Event Name *"
            />
            <p
              class="text-red-500 font-semibold mt-2"
              v-if="v$.eventName.$error"
            >
              {{ v$.eventName.$errors[0].$message }}
            </p>
          </div>
          <!-- eventDate -->
          <div>
            <Datepicker
              v-model="eventDate"
              :enableTimePicker="false"
              position="left"
              :autoApply="true"
              :format="format"
              calendarClassName="inter"
            >
              <template #dp-input="{ value }">
                <div>
                  <input
                    @blur="v$.eventDate.$touch()"
                    :value="value"
                    type="text"
                    name="eventDate"
                    id="eventDate"
                    class="
                      block
                      w-full
                      shadow-sm
                      py-3
                      px-4
                      placeholder-gray-500
                      rounded-md
                      bg-gray-100
                      font-sans
                    "
                    :class="
                      v$.eventDate.$error
                        ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                        : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
                    "
                    placeholder="Event Date *"
                  />
                  <p
                    class="text-red-500 font-semibold mt-2 font-sans"
                    v-if="v$.eventDate.$error"
                  >
                    {{ v$.eventDate.$errors[0].$message }}
                  </p>
                </div>
              </template>
            </Datepicker>
          </div>
          <!-- form error -->
          <div
            class="rounded-md bg-red-50 p-4 border border-red-300"
            v-if="error"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <i
                  class="mdi mdi-alert-circle-outline text-2xl text-red-600"
                ></i>
              </div>
              <div class="ml-3">
                <h3 class="text-base font-medium text-red-800">Error</h3>
                <div class="mt-2 text-sm text-red-700">
                  <div
                    class="font-semibold"
                    v-if="
                      error ==
                      'The email address is already in use by another account.'
                    "
                  >
                    <p>{{ error }}</p>
                    <p
                      class="mt-3 href"
                      @click="$store.state.authModal.action = 'signin'"
                    >
                      <i class="mdi mdi-alert mr-1"></i>
                      Please click here to sign in
                    </p>
                  </div>
                  <div class="font-semibold" v-else>
                    <p>{{ error }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  buttons -->
          <div class="flex flex-col gap-8 mt-4">
            <!-- submit -->
            <button
              :disabled="loading"
              @click="submit"
              class="
                uppercase
                bg-blue-600
                text-blue-50
                hover:bg-blue-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-2
                space-x-2
                inline-flex
                items-center
                justify-between
              "
            >
              <span>Sign Up</span>
              <i v-if="!loading" class="mdi mdi-account-check text-lg"></i>
              <svg
                v-if="loading"
                class="animate-spin h-5 w-5 text-blue-100"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-50"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
            <!-- signin -->
            <button
              @click="$store.state.authModal.action = 'signin'"
              class="
                text-sm text-blue-700
                transition
                font-medium
                hover:text-blue-500
                sm:mr-4
                md:mr-0 md:mb-6
              "
            >
              <span class="block">Already have an account?</span>
              <span class="block">Click here to sign in.</span>
            </button>
          </div>
        </div>
      </div>
      <!-- col 2 / features -->
      <Features />
    </div>
  </transition>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import axios from "axios";
import NProgress from "nprogress";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import moment from "moment";
import { firebase, db } from "@/firebase";
import Features from "@/components/AuthModal/Features";
export default {
  components: { Datepicker, Features },
  data: () => ({
    title: "Sign Up for a Free Account",
    subtitle: "Please complete the form below to sign up",
    v$: useVuelidate(),
    toast: useToast(),
    email: "",
    eventName: "",
    eventDate: "",
    success: false,
    error: false,
    loading: false,
    acList: "6",
  }),
  computed: {
    axiosUrl() {
      if (process.env.NODE_ENV == "development") return "http://localhost:5000";
      return "https://api.silentauctionvacations.com";
    },
  },
  methods: {
    format(date) {
      if (!date) return;
      return moment(date).format("ddd, MM/DD/YYYY");
    },
    async submit() {
      // 1. create auth user
      // 2. add to users collection
      // 3. subscribe to active campaign

      this.v$.$validate();
      // if error
      if (this.v$.$error)
        return this.toast.error("Please check form for errors");

      // submit form
      NProgress.start();
      this.loading = true;

      try {
        // create auth user
        let authAccount = await firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.email.toLowerCase(),
            "^5LHf1p#LejCyh#1^i@Km7l^"
          );

        // add to users collection
        await db
          .collection("users")
          .doc(authAccount.user.uid)
          .set({
            dateRegistered: new Date().toISOString().substring(0, 10),
            email: this.email.toLowerCase(),
            events: [
              {
                active: true,
                eventDate: this.eventDate.toISOString().substring(0, 10),
                eventDateAutoClose: moment(this.eventDate)
                  .add(90, "days")
                  .toISOString()
                  .substr(0, 10),
                eventName: this.eventName,
                wishListItems: [],
              },
            ],
            lastSignedIn: new Date().toISOString().substring(0, 10),
            name: "",
            notifications: [],
            organization: "",
            phone: "",
            regSource: this.$store.state.selectedPartner.name,
            source: "",
            spokenWith: "",
            wishList: [],
          });
        // subscribe to activeCampaign
        // adds user to list 6
        let data = {
          selectedPartner: this.$store.state.selectedPartner,
          email: this.email,
          eventDate: this.eventDate.toISOString().substring(0, 10),
          eventName: this.eventName,
          env: process.env.NODE_ENV,
          acList: this.acList,
        };
        let url = this.axiosUrl + "/auctionvacations.org/subscribe";
        try {
          let subscribeToAc = await axios.post(url, data);
          if (subscribeToAc.status === 200) {
            // send new registration email
            let url2 = this.axiosUrl + "/auctionvacations.org/sign-up";
            await axios.post(url2, data);
            this.toast.success(
              "You've successfully signed up with email " + this.email
            );
            this.success = true;
            this.error = false;
            this.loading = false;
            this.$store.state.authModal.display = false;
            NProgress.done();
            return;
          }
        } catch (error) {
          return this.handleError(error);
        }
      } catch (error) {
        return this.handleError(error);
      }
    },
    handleError(error) {
      this.toast.error(error.message);
      this.success = false;
      this.error = error.message;
      this.loading = false;
      NProgress.done();
      return;
    },
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      eventName: {
        required: helpers.withMessage("Event name is required", required),
      },
      eventDate: {
        required: helpers.withMessage("Event date is required", required),
      },
    };
  },
};
</script>