<template>
  <transition name="fade">
    <div class="relative">
      <!-- overlay to close on click outside-->
      <div
        class="fixed inset-0 z-10 bg-gray-900 bg-opacity-30"
        v-if="showMenu"
        @click="showMenu = false"
      ></div>

      <!-- activator -->
      <div
        class="
          rounded-full
          py-2
          px-4
          text-blue-50
          bg-blue-50 bg-opacity-25
          cursor-pointer
          flex
          items-center
          hover:bg-blue-700
          transition
          relative
        "
        @click="showMenu = !showMenu"
      >
        <div class="mr-5">Menu</div>
        <div class="absolute top-2 right-3">
          <i class="mdi mdi-chevron-down"></i>
        </div>
      </div>

      <!-- dropdown menu -->
      <transition name="fade">
        <div
          class="
            absolute
            right-2
            top-12
            bg-white
            dark:bg-gray-600
            text-gray-700
            dark:text-gray-300
            z-10
            shadow
            rounded
            text-sm
            overflow-hidden
            p-2
            grid grid-cols-2
            gap-1
            w-500
          "
          v-if="showMenu"
        >
          <!-- post event reporting -->
          <div>
            <a
              @click="showMenu = false"
              :href="
                'https://savtravelerportal.com/invoice?ref=' +
                $store.state.selectedPartner.id
              "
              target="_blank"
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
                rounded
              "
            >
              <div>
                <i class="mdi mdi-briefcase-variant text-xl text-blue-600"></i>
              </div>
              <div>Post Event Reporting</div></a
            >
          </div>
          <!-- items -->
          <div v-for="(item, index) in items" :key="index">
            <a
              @click="onClick(item)"
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
                rounded
              "
            >
              <div>
                <i class="mdi text-xl text-blue-600" :class="item.icon"></i>
              </div>
              <div>{{ item.text }}</div>
            </a>
          </div>
          <!-- free promos and tips -->
          <div>
            <a
              @click="
                showMenu = false;
                $store.state.subscribeModal = true;
              "
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
                rounded
              "
            >
              <div>
                <i class="mdi mdi-bell text-xl text-blue-600"></i>
              </div>
              <div>Free Promos &amp; Tips</div></a
            >
          </div>
          <!-- share -->
          <div>
            <a
              @click="copyAndShare"
              class="
                hover:bg-gray-200
                dark:hover:bg-gray-500
                py-2
                pl-3
                transition
                cursor-pointer
                font-semibold
                flex
                items-center
                space-x-4
                rounded
              "
            >
              <div>
                <i class="mdi mdi-share-variant text-xl text-blue-600"></i>
              </div>
              <div>Share This Website</div></a
            >
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  data: () => ({
    toast: useToast(),
    showMenu: false,
  }),
  computed: {
    items() {
      return [
        {
          text: "Best Selling Packages",
          icon: "mdi-thumb-up",
          path: "/" + this.$store.state.selectedPartner.id + "/best-sellers",
        },
        {
          text: "All Inclusive Resorts",
          icon: "mdi-check-circle",
          path: "/" + this.$store.state.selectedPartner.id + "/all-inclusive",
        },
        {
          text: "Castlemartyr Resort",
          icon: "mdi-castle",
          path: "/" + this.$store.state.selectedPartner.id + "/castlemartyr",
        },
        {
          text: "Knightsbrook Hotel",
          icon: "mdi-office-building",
          path: "/" + this.$store.state.selectedPartner.id + "/knightsbrook",
        },
        {
          text: "Europe & Ireland Packages",
          icon: "mdi-map-marker",
          path: "/" + this.$store.state.selectedPartner.id + "/europe-ireland",
        },
        {
          text: "Golf Packages",
          icon: "mdi-golf",
          path: "/" + this.$store.state.selectedPartner.id + "/golf",
        },
        {
          text: "Family Friendly Packages",
          icon: "mdi-emoticon",
          path: "/" + this.$store.state.selectedPartner.id + "/family",
        },
        {
          text: "Cruise Packages",
          icon: "mdi-sail-boat",
          path: "/" + this.$store.state.selectedPartner.id + "/cruises",
        },
        {
          text: "Exotic Destinations",
          icon: "mdi-emoticon-cool",
          path: "/" + this.$store.state.selectedPartner.id + "/exotic",
        },
        {
          text: "Donations for Vacations",
          icon: "mdi-cash-marker",
          path:
            "/" +
            this.$store.state.selectedPartner.id +
            "/donations-for-vacations",
        },
        {
          text: "COVID-19 Response",
          icon: "mdi-medical-bag",
          path: "/" + this.$store.state.selectedPartner.id + "/contact",
        },
        {
          text: "How It Works",
          icon: "mdi-chat-question",
          path: "/" + this.$store.state.selectedPartner.id + "/how-it-works",
        },
        {
          text: "Traveler FAQ's",
          icon: "mdi-help-circle",
          path: "/" + this.$store.state.selectedPartner.id + "/traveler-faqs",
        },
        {
          text: "Package Guide",
          icon: "mdi-chart-box",
          path: "/" + this.$store.state.selectedPartner.id + "/package-guide",
        },
      ];
    },
  },
  methods: {
    copyAndShare() {
      let urlToShare = window.location.href;
      navigator.clipboard.writeText(urlToShare).then(() => {
        this.showMenu = false;
        this.toast.success(
          "URL successfully copied. Just paste it anywhere you'd like to share! Thanks! 😀"
        );
      });
    },
    onClick(item) {
      if (!item) return;
      this.$router.push(item.path);
      this.showMenu = false;
    },
  },
};
</script>