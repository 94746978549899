<template>
  <div
    class="
      font-medium
      mb-4
      pt-10
      space-y-6
      border-t border-gray-400
      md:border-none
    "
  >
    <!-- title -->
    <div class="font-semibold text-lg sm:text-xl">{{ title }}</div>
    <!-- items -->
    <div
      class="flex items-center space-x-3 sm:text-lg"
      v-for="(item, index) in items"
      :key="index"
    >
      <i class="mdi mdi-check-circle text-xl text-green-600"></i>
      <div>{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: "Why sign up for a free account?",
    items: [
      "Print/Download Package PDF's",
      "Secure Today's Pricing",
      "Instant Access to All Event Materials",
      "Priority Event Support",
      "Create a Wish List",
    ],
  }),
};
</script>