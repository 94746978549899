<template>
  <transition name="fade">
    <!-- wrapper -->
    <div
      @click="close"
      v-if="$store.state.subscribeModal"
      class="
        fixed
        inset-0
        bg-gray-900 bg-opacity-80
        close
        flex
        items-center
        justify-center
      "
    >
      <!-- dialog card -->
      <div class="bg-gray-50 rounded shadow p-4 max-w-xl w-full relative">
        <!-- close btn -->
        <button
          @click="$store.state.subscribeModal = false"
          class="
            cursor-pointer
            hover:bg-gray-600 hover:bg-opacity-25
            transition
            rounded-full
            h-10
            w-10
            flex
            items-center
            justify-center
            text-center
            close
            absolute
            top-2
            right-2
          "
        >
          <i
            class="mdi mdi-close-circle-outline text-2xl text-gray-600"
            style="margin-left: 1px; margin-top: 1px"
          ></i>
        </button>
        <!-- title -->
        <h3 class="text-2xl font-semibold uppercase mr-10">
          Subscribe for Freebies
        </h3>
        <!-- subtitle -->
        <div class="font-medium my-6 text-lg" v-if="!success">
          Join our email newsletter for up to date promotions, discounts,
          contests, event tips, &amp; success stories.
        </div>
        <!-- form error -->
        <div
          class="rounded-md bg-red-50 p-4 border border-red-300"
          v-if="error"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <i class="mdi mdi-alert-circle-outline text-2xl text-red-600"></i>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                The form did not submit successfully
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <p>
                  We have not received your contact request. Please email us
                  directly at
                  <a
                    href="mailto:booking@silentauctionvacations.com"
                    class="href"
                    >booking@silentauctionvacations.com.</a
                  >
                  Thank you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- form success -->
        <div
          class="rounded-md bg-green-50 p-4 border border-green-300 mt-6"
          v-if="success"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <i
                class="mdi mdi-check-circle-outline text-2xl text-green-600"
              ></i>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Form Successfully Submitted
              </h3>
              <div class="mt-2 text-sm text-green-700">
                <p>
                  You've successfully subscribed. Please keep an eye on your
                  inbox for our next email. Thank you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- form -->
        <div class="space-y-6" v-if="!error && !success">
          <!-- email -->
          <div>
            <input
              @blur="v$.email.$touch()"
              v-model.trim="email"
              type="email"
              name="email"
              id="email"
              autocomplete="email"
              :disabled="loading"
              class="
                block
                w-full
                shadow-sm
                py-3
                px-4
                placeholder-gray-500
                rounded-md
                bg-gray-100
              "
              :class="
                v$.email.$error
                  ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
              "
              placeholder="Email *"
            />
            <p class="text-red-500 font-semibold mt-2" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}
            </p>
          </div>

          <!-- unsubscribe anytime -->
          <div class="text-sm">
            <p>
              <i>Your email address is completely safe with us. </i>
              <span class="font-medium">Unsubscribe anytime.</span>
            </p>
          </div>

          <!-- submit button -->
          <div>
            <button
              :disabled="loading"
              @click="submit"
              class="
                uppercase
                bg-blue-600
                text-blue-50
                hover:bg-blue-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-2
                space-x-2
                inline-flex
                items-center
                justify-between
              "
            >
              <span>Subscribe</span>
              <i v-if="!loading" class="mdi mdi-bell text-lg"></i>
              <svg
                v-if="loading"
                class="animate-spin h-5 w-5 text-blue-100"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-50"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import axios from "axios";
import NProgress from "nprogress";
export default {
  data: () => ({
    v$: useVuelidate(),
    toast: useToast(),
    email: "",
    success: false,
    error: false,
    loading: false,
    acList: "6",
  }),
  computed: {
    axiosUrl() {
      if (process.env.NODE_ENV == "development") return "http://localhost:5000";
      return "https://api.silentauctionvacations.com";
    },
  },
  methods: {
    close(e) {
      if (e.target.classList.contains("close"))
        this.$store.state.subscribeModal = false;
    },
    submit() {
      this.v$.$validate();
      // if error
      if (this.v$.$error)
        return this.toast.error("Please check form for errors");
      // submit form
      NProgress.start();
      this.loading = true;
      let data = {
        selectedPartner: this.$store.state.selectedPartner,
        email: this.email,
        env: process.env.NODE_ENV,
        acList: this.acList,
      };
      let url = this.axiosUrl + "/auctionvacations.org/subscribe";
      axios.post(url, data).then((res) => {
        if (res.status == 200) {
          this.toast.success("Form submitted successfully");
          this.success = true;
          this.error = false;
          this.loading = false;
          NProgress.done();
          return;
        } else {
          this.toast.error("The form did not submit successfully");
          this.success = false;
          this.error = true;
          this.loading = false;
          NProgress.done();
          return;
        }
      });
    },
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };
  },
};
</script>