// connects to sav main => not portals

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyCP6yMrCtDWNbMgUwdLyeO3BDRAJwGCNYk",
  authDomain: "silent-auction-vacations.firebaseapp.com",
  databaseURL: "https://silent-auction-vacations.firebaseio.com",
  projectId: "silent-auction-vacations",
  storageBucket: "silent-auction-vacations.appspot.com",
  messagingSenderId: "1044426753281",
  appId: "1:1044426753281:web:467f396e93c5c413c60700"
}

firebase.initializeApp(config)

const db = firebase.firestore()

export {
  firebase,
  db
}