<template>
  <transition name="fade">
    <button
      @click="scrollToTop"
      v-show="show"
      class="
        fixed
        bottom-5
        right-5
        rounded-full
        h-12
        w-12
        bg-blue-700
        hover:bg-blue-800
        transition
        text-blue-50
        shadow
      "
    >
      <i class="mdi mdi-arrow-up text-2xl"></i>
    </button>
  </transition>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.watchScroll);
  },
  data: () => ({
    show: false,
  }),
  methods: {
    scrollToTop() {
      this.$smoothScroll({
        scrollTo: 0, // scrollTo can be element or number
        updateHistory: false,
      });
    },
    watchScroll() {
      if (typeof window === "undefined") return;
      if (window.pageYOffset > 600) return (this.show = true);
      return (this.show = false);
    },
  },
};
</script>