
import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"
import NProgress from "nprogress";

const routes = [
  {
    path: '/',
    name: "Root",
    component: () => import(/* webpackChunkName: "Root" */ '@/views/Home.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    },
    beforeEnter: (to, from, next) => {
      // checking for localStorage selectedPartner
      let localStorageSelectedPartner = localStorage.getItem('selectedPartner')
      // if localStorage found
      if (localStorageSelectedPartner != null) {
        localStorageSelectedPartner = JSON.parse(localStorageSelectedPartner)
        next(`/${localStorageSelectedPartner.id}`)
      } else {
        next('/av')
      }
    }
  },
  {
    path: '/:partner/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    meta: {
      mainNavLink: true,
      description: ""
    }
  },
  {
    path: '/:partner/experiences',
    name: 'Experiences',
    component: () => import(/* webpackChunkName: "Experiences" */ '@/views/Experiences.vue'),
    meta: {
      mainNavLink: true,
      description: "Looking for something memorable? Experiences include accommodations, as well as something fun to do once you're there."
    }
  },
  {
    path: '/:partner/new',
    name: `New for ${new Date().getFullYear()}`,
    component: () => import(/* webpackChunkName: "New" */ '@/views/New.vue'),
    meta: {
      mainNavLink: true,
      description: `You spoke, we listened. Here are the brand new packages for ${new Date().getFullYear()}.`
    }
  },
  {
    path: '/:partner/american',
    name: 'All American',
    component: () => import(/* webpackChunkName: "American" */ '@/views/American.vue'),
    meta: {
      mainNavLink: true,
      description: "Looking to travel within the United States? These auction vacation packages are made for you."
    }
  },
  {
    path: '/:partner/classic',
    name: 'Classic Packages',
    component: () => import(/* webpackChunkName: "Classic" */ '@/views/Classic.vue'),
    meta: {
      mainNavLink: true,
      description: "The auction vacation packages you already know and love"
    }
  },
  {
    path: '/:partner/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue'),
    meta: {
      mainNavLink: true,
      description: ""
    }
  },
  {
    path: '/:partner/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue'),
    meta: {
      mainNavLink: true,
      description: ""
    }
  },
  {
    path: '/:partner/best-sellers',
    name: 'Our Best Selling Packages',
    component: () => import(/* webpackChunkName: "BestSellers" */ '@/views/Best-Sellers.vue'),
    meta: {
      mainNavLink: false,
      description: "A great starting point for any event"
    }
  },
  {
    path: '/:partner/traveler-faqs',
    name: "Traveler FAQ's",
    component: () => import(/* webpackChunkName: "TravelerFaqs" */ '@/views/Traveler-Faqs.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/all-inclusive',
    name: "All Inclusive Resorts",
    component: () => import(/* webpackChunkName: "AllInclusiveResorts" */ '@/views/All-Inclusive.vue'),
    meta: {
      mainNavLink: false,
      description: "Leave your wallet at home. Covers all meals, beverages, resort activities, and non motorized water sports."
    }
  },
  {
    path: '/:partner/castlemartyr',
    name: "Castlemartyr",
    component: () => import(/* webpackChunkName: "Castlemartyr" */ '@/views/Castlemartyr.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/knightsbrook',
    name: "Knightsbrook",
    component: () => import(/* webpackChunkName: "Knightsbrook" */ '@/views/Knightsbrook.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/europe-ireland',
    name: "Europe & Ireland",
    component: () => import(/* webpackChunkName: "EuropeIreland" */ '@/views/Europe-Ireland.vue'),
    meta: {
      mainNavLink: false,
      description: "Explore the rich history and majesty of Europe"
    }
  },
  {
    path: '/:partner/how-it-works',
    name: "How It Works",
    component: () => import(/* webpackChunkName: "HowItWorks" */ '@/views/How-It-Works.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/golf',
    name: "Golf Packages",
    component: () => import(/* webpackChunkName: "GolfPackages" */ '@/views/Golf.vue'),
    meta: {
      mainNavLink: false,
      description: "Championship Level Courses"
    }
  },
  {
    path: '/:partner/family',
    name: "Family Friendly Packages",
    component: () => import(/* webpackChunkName: "Family" */ '@/views/Family.vue'),
    meta: {
      mainNavLink: false,
      description: "Beach getaways, ski resorts, family fun."
    }
  },
  {
    path: '/:partner/exotic',
    name: "Exotic Destinations",
    component: () => import(/* webpackChunkName: "Exotic" */ '@/views/Exotic.vue'),
    meta: {
      mainNavLink: false,
      description: "Beach getaways, island retreats, beautiful Europe."
    }
  },
  {
    path: '/:partner/account',
    name: "Account",
    component: () => import(/* webpackChunkName: "Account" */ '@/views/Account.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/donations-for-vacations',
    name: "Donations for Vacations",
    component: () => import(/* webpackChunkName: "DonationsForVacations" */ '@/views/DonationsForVacations.vue'),
    meta: {
      mainNavLink: false,
      description: "Beach getaways, island retreats, beautiful Europe."
    }
  },
  {
    path: '/:partner/cruises',
    name: "Cruise Packages",
    component: () => import(/* webpackChunkName: "Cruises" */ '@/views/Cruises.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/package-guide',
    name: "Package Guide",
    component: () => import(/* webpackChunkName: "PackageGuide" */ '@/views/Package-Guide.vue'),
    meta: {
      mainNavLink: false,
      description: "PDF packages are updated often due to property, cost, and other detail changes. Please do not use outdated materials at events, but print and/or display the most recent versions from this website. Website materials override printed materials when different. Please represent and/or print the latest version each time."
    }
  },
  {
    path: '/:partner/wishlist',
    name: "Wish List",
    component: () => import(/* webpackChunkName: "WishList" */ '@/views/Wishlist.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/destinations/:destination',
    name: 'Destination',
    component: () => import(/* webpackChunkName: "Destination" */ '@/views/_destination.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/:pathMatch(.*)*',
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  },
  {
    path: '/:partner/p/:packageCode',
    name: 'Package',
    component: () => import(/* webpackChunkName: "Package" */ '@/views/_packageCode.vue'),
    meta: {
      mainNavLink: false,
      description: ""
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, left: 0 }
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  store.state.mobileNavDrawer = false;
  // checks and sets selectedPartner before every route
  let partner = to.params.partner
  // partner param present
  if (partner != undefined) {
    let partnerFound = store.state.partners.find((i) => i.id == partner)
    // partner found in store
    if (partnerFound && Object.keys(partnerFound).length != 0) {
      store.state.selectedPartner = partnerFound
      localStorage.setItem("selectedPartner", JSON.stringify(partnerFound))
      next()
    } else {
      // partner not found
      next('/av/404')
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
