<template>
  <transition name="fade">
    <div v-if="$store.state.authModal.action == 'signin'">
      <!-- title -->
      <h3 class="text-xl font-semibold uppercase mr-6 mb-6">
        {{ title }}
      </h3>
      <!-- form -->
      <!-- email -->
      <div class="max-w-lg">
        <input
          @blur="v$.email.$touch()"
          v-model.trim="email"
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          :disabled="loading"
          class="
            block
            w-full
            shadow-sm
            py-3
            px-4
            placeholder-gray-500
            rounded-md
            bg-gray-100
          "
          :class="
            v$.email.$error
              ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
              : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
          "
          placeholder="Email *"
        />
        <p class="text-red-500 font-semibold mt-2" v-if="v$.email.$error">
          {{ v$.email.$errors[0].$message }}
        </p>
      </div>
      <!-- form error -->
      <div
        class="rounded-md max-w-lg mt-6 bg-red-50 p-4 border border-red-300"
        v-if="error"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <i class="mdi mdi-alert-circle-outline text-2xl text-red-600"></i>
          </div>
          <div class="ml-3">
            <h3 class="text-base font-medium text-red-800">Error</h3>
            <div class="mt-2 text-sm text-red-700">
              <div
                class="font-semibold"
                v-if="
                  error ==
                  'There is no user record corresponding to this identifier. The user may have been deleted.'
                "
              >
                <p>{{ error }}</p>
                <p
                  class="mt-3 href"
                  @click="
                    $store.state.authModal.action = 'signup';
                    email = '';
                    error = false;
                  "
                >
                  <i class="mdi mdi-alert mr-1"></i>
                  Please click here to sign up
                </p>
              </div>
              <div class="font-semibold" v-else>
                <p>{{ error }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  buttons -->
      <div
        class="
          gap-4
          sm:gap-12
          mt-5
          flex flex-col
          items-center
          justify-between
          max-w-lg
          sm:flex-row
        "
      >
        <!-- submit -->
        <button
          :disabled="loading"
          @click="submit"
          class="
            uppercase
            bg-blue-600
            text-blue-50
            hover:bg-blue-700
            font-semibold
            cursor-pointer
            text-sm
            tracking-wide
            transition
            rounded-full
            px-4
            py-2
            space-x-2
            inline-flex
            items-center
            justify-between
            flex-1
            w-full
          "
        >
          <span>Sign In</span>
          <i v-if="!loading" class="mdi mdi-account-check text-lg"></i>
          <svg
            v-if="loading"
            class="animate-spin h-5 w-5 text-blue-100"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-50"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
        <!-- signin -->
        <button
          @click="$store.state.authModal.action = 'signup'"
          class="
            text-sm text-blue-700
            transition
            font-medium
            hover:text-blue-500
          "
        >
          <span>Need an account? Click here to sign up.</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { firebase } from "@/firebase";
import NProgress from "nprogress";
export default {
  data: () => ({
    v$: useVuelidate(),
    toast: useToast(),
    title: "Sign In to Your Account",
    email: "",
    error: false,
    loading: false,
  }),
  methods: {
    submit() {
      // 1. sign in user

      this.v$.$validate();
      // if error
      if (this.v$.$error)
        return this.toast.error("Please check form for errors");

      // submit form
      NProgress.start();
      this.loading = true;

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, "^5LHf1p#LejCyh#1^i@Km7l^")
        .then((userCredential) => {
          var user = userCredential.user;
          this.$store.state.user = user;
          this.error = false;
          this.loading = false;
          this.$store.state.authModal.display = false;
          NProgress.done();
          this.toast.success("Sign In Successful");
        })
        .catch((error) => {
          this.toast.error(error.message);
          this.error = error.message;
          this.loading = false;
          NProgress.done();
          return;
        });
    },
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };
  },
};
</script>
